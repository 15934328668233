import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const AlignPlus = () => {
  const headingbanner = {
    title: `Align Plus`,
    content: `Software For Management Consultancy`,
  };

  const data = {
    images: ["align-plus.webp", "align-plus-2.webp"],
    firstAlt: "Login Screen of a software for management Consultancy",
    firstTitle: "Login Screen",
    secondAlt: "Camapign Assessment status of the user",
    secondTitle: "Campaign Assessment",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "Angular Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main challenge was to maintain consistency across their nine different offerings and create a modern UI design. `,
          `We needed to ensure that the user experience and feedback remained consistent across all tools, while also creating guided campaigns to streamline the user's journey. `,
          `Additionally, we needed to simplify the information architecture to enhance usability and create modern and descriptive data visuals to effectively convey information to the users.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our solution focused on simplifying user flows and guiding users through their journey. We achieved this by creating interactive data visuals that offer an intuitive experience. `,
          `Additionally, we employed a minimal UI design approach to ensure a clean and uncluttered interface. Guided flows and actions were implemented to provide users with a clear path to follow while optimizing viewport space allowed for maximum visibility of the data visuals.`,
          `Overall, our solution aimed to create an engaging experience by combining simplified user flows, intuitive data visuals, and efficient use of screen space.`,
        ],
      },
    ],
    image: ["align-plus-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: "Login",
    para: [
      `We created a login screen to offer privacy, security, personalisation etc. to the user for improved platform experience. `,
    ],
    content: {
      imageAlt: "Login Screen",
      imageTitle: "Login Screen",
      image: ["align-plus-projectone.webp"],
      text: `This login screen requires users to enter their credentials before accessing a system for improved security, user experience, and data protection.`,
    },
    content2: {
      image: ["align-plus-projectone-2.webp"],
      text: `We also created a login success screen for the user for improved platform experience.`,
      imageAlt: "UI of Success Screen",
      imageTitle: "Success Screen",
    },
  };

  const projectData2 = {
    title: `Home Page`,
    para: [
      `The homepage shows all the assessments that a user has completed or has to complete. It ensures personalisation and improves productivity.`,
    ],
    content: {
      image: ["align-plus-projecttwo.webp"],
      imageAlt: "UI Screen to show the list of survey",
      imageTitle: "Home Page",
    },
  };

  const projectData3 = {
    title: "Strategy Report",
    para: [
      `The platform also provides a comprehensive report based on the result obtained from the assessment ensuring informed business decision making. `,
    ],
    content: {
      image: [
        "align-plus-projectthree.webp",
        "align-plus-projectthree-2.webp",
        "align-plus-projectthree-3.webp",
        "align-plus-projectthree-4.webp",
      ],
      firstAlt: "UI to add remark",
      firstTitle: "Remarks",
      secondAlt: "UI screen to collect customer responses",
      secondTitle: "Responses",
      thirdAlt: "UI to check the strategy report index",
      thirdTitle: "Strategy Report- Index",
      fourthAlt: "UI to check strategy report insight",
      fourthTitle: "Strategy Report- Insight",
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our software solution for management consultancy successfully addressed the challenge of maintaining consistency across multiple offerings while creating a modern and user-friendly interface.`,
        `By simplifying user flows, utilizing interactive data visuals, and implementing guided campaigns, we were able to enhance usability and provide an engaging experience for users. `,
        `Overall, our solution aimed to catalyze organizational success through strategic assessments and effectively convey information to users.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={38} />
      </div>
    </Layout>
  );
};

export default AlignPlus;

export const Head = () => (
  <Seo
    title="Align Plus Case Study- Octet Design Studio"
    description="We did end to end UI UX designing of complete Align+ suite. We worked on this project from ideation to UI design with AngularJs framework."
  />
);
